<template>
  <div class="service">
    <div class="service_content">
      <div class="content">
        <!-- 顶部面包屑 s -->
        <bread-crumb :nav_title="nav_title" path="order" />
        <!-- 顶部面包屑 end-->

        <!-- 服务节点 -->
        <div class="complete_evalution">
          <div class="service_bg">
            <div class="service_jiedian">
              <el-steps
                :space="300"
                :active="6"
                finish-status="success"
                align-center
              >
                <el-step title="提交预约"></el-step>
                <el-step title="已匹配"></el-step>
                <el-step title="全部结束"></el-step>
                <el-step title="全部结算"></el-step>
                <el-step
                  v-if="orders.schedule.progress_code > 5"
                  title="已评价"
                ></el-step>
                <el-step
                  v-if="orders.schedule.progress_code >= 6"
                  title="完成"
                ></el-step>
              </el-steps>
            </div>
          </div>
        </div>
        <!-- 订单详情 title-->
        <div class="service_details">
          <div class="no_evalution_title">
            <tr>
              <th style="width: 364px">订单详情</th>
              <th style="width: 116px">学生姓名</th>
              <th style="width: 181px">手机号码</th>
              <th style="width: 195px">合同编号</th>
              <th style="width: 175px">课程状态</th>
              <th style="width: 124px;">付款状态</th>
            </tr>
          </div>
          <!-- 订单表格 -->
          <div class="service_nums">
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td
                  colspan="6"
                  style="height: 30px; text-align: left; padding-left: 20px;border-top:none;border-right:none;"
                >
                  订单编号：<span>{{ orders.schedule.order_sn }}</span>
                </td>
              </tr>
              <tr style="border-top:1px solid #ededed;">
                <td style="width: 364px;">
                  <div class="no_evalution_users">
                    <div class="users_info">
                      <el-avatar
                        src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                      />
                      <div class="users_detail">
                        <span>{{ orders.info.teacher_name }}</span>
                        <p>{{ orders.info.hs_service_name }}</p>
                      </div>
                    </div>
                    <div class="yuyue_times">
                      预约时间：<span>{{ orders.info.created_at }}</span>
                    </div>
                  </div>
                </td>
                <td style="width: 116px">{{ orders.info.user_name }}</td>
                <td style="width: 181px">{{ orders.info.mobile }}</td>
                <td style="width: 195px">{{ orders.info.contract_number }}</td>
                <td
                  style="width: 195px"
                  v-if="orders.schedule.progress_code == 1"
                >
                  提交预约
                </td>
                <td
                  style="width: 195px"
                  v-if="orders.schedule.progress_code == 2"
                >
                  已匹配
                </td>
                <td
                  style="width: 195px"
                  v-if="orders.schedule.progress_code == 3"
                >
                  全部结束
                </td>
                <td
                  style="width: 195px"
                  v-if="orders.schedule.progress_code == 4"
                >
                  已结束
                </td>
                <td
                  style="width: 195px"
                  v-if="orders.schedule.progress_code == 5"
                >
                  已评价
                </td>
                <td
                  style="width: 195px"
                  v-if="orders.schedule.progress_code == 6"
                >
                  全部完成
                </td>
                <td class="settlement" style="width: 124px;border-right:none;">
                  {{ orders.info.is_settlement == 1 ? "全部结算" : "未结算" }}
                </td>
              </tr>
            </table>
          </div>
          <!-- 评价信息表格 -->
          <div class="evaluation_table">
            <table
              cellspacing="0"
              cellpadding="0"
              v-for="(item, index) in acore"
              :key="index"
            >
              <tr v-if="item.type == type">
                <td colspan="" class="no_number" style="border-left:1px solid #ededed;border-radius:4px 0 0 0;">评价信息</td>
                <td class="no_number">总体满意度</td>
                <td class="no_number">海师姓名</td>
                <td class="no_number">评价人</td>
                <td class="no_number" style="border-radius:0 4px 0 0;">操作</td>
              </tr>
              <tr v-if="item.type == type">
                <td style="width: 457px; border-left: 1px solid #ededed;border-radius:0 0 0 4px;">
                  <div class="evaluation_info">
                    {{ item.content }}
                  </div>
                </td>
                <td style="width: 205px">
                  <div class="block">
                    <el-rate
                      v-model="item.star"
                      :colors="colors"
                      disabled
                    ></el-rate>
                    <span class="demonstration" v-if="item.star == 1">差</span>
                    <span class="demonstration" v-else-if="item.star == 2"
                      >还需努力</span
                    >
                    <span class="demonstration" v-else-if="item.star == 3"
                      >加油</span
                    >
                    <span class="demonstration" v-else-if="item.star == 4"
                      >很好</span
                    >
                    <span class="demonstration" v-else-if="item.star == 5"
                      >非常棒</span
                    >
                  </div>
                </td>
                <td style="width: 195px">{{ item.teacher_name }}</td>
                <td class="overs" style="width: 175px">{{ item.user_name }}</td>
                <td class="settlement" style="width: 124px;border-radius:0 0 4px 0px;">
                  <button
                    class="video"
                    onclick="window.location.href = 'http://naae.oss-cn-hangzhou.aliyuncs.com/beliwin/202104221724%282%29.mp4'"
                  >
                    <i class="iconfont xhgplay"></i>播放
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "../personal/components/breadcrumb";
import { getOrderEvaluation, orderDetail } from "@/api/order.js";

export default {
  data() {
    return {
      value1: 4, //显示几星
      colors: ["#F3BC70", "#F3BC70", "#F3BC70"],
      nav_title: [
        { name: "订单中心" },
        { name: "服务评价", path: "order" },
        { name: "评价详情" },
      ],
      orders: {
        info: {},
        schedule: {},
      },
      acore: "",
      type: 1,
    };
  },
  components: {
    breadCrumb: breadCrumb,
  },
  methods: {
    Inits() {
      let id = this.$route.query.id;
      // console.log(1111,id)
      this.type = this.$route.query.type;

      getOrderEvaluation({ id }).then((res) => {
        // 评价
        this.acore = res.data;
        // console.log(res)
      });
      orderDetail({ id }).then((res) => {
        //订单详情
        this.orders = res.data;
        console.log(this.orders);
      });
    },
  },
  mounted() {
    this.Inits();
  },
};
</script>

<style lang="scss" scoped>
.service {
  background: #f5f5f5;
  padding-bottom: 30px;

  .service_content {
    margin: 0 auto;
    width: 1200px;

    // 服务节点
    .service_bg {
      height: 215px;
      background: url("../personal/images/bjt.png") no-repeat center;
      .service_jiedian {
        padding: 76px 107px 75px 117px;
        /deep/.el-step__icon {
          width: 35px;
          height: 35px;
          border: 5px solid #ededed;
          color: #ffffff;
          background: #009349;
        }
        /deep/ .el-step.is-horizontal .el-step__line {
          height: 4px;
          top: 16px;
          background: #009349;
          .el-step__line-inner {
            border-style: none;
          }
        }
        /deep/.el-step__title.is-success {
          font-size: 14px;
          font-weight: 500;
          color: #666666;
        }
      }
    }
    // 评价详情
    .service_details {
      width: 1200px;
      height: 396px;
      background: #ffffff;
      border-radius: 8px;
      padding: 20px;
      margin-top: 20px;
      .no_evalution_title {
        width: 1160px;
        height: 50px;
        background: #f5f5f5;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 500;
        color: #666666;
        line-height: 50px;
        text-align: center;
      }
      // 订单详情
      .service_nums {
        margin-top: 20px;
        width: 1160px;
        height: 133px;
        border-radius: 4px;
        border: 1px solid #ededed;
        text-align: center;

        .no_number {
          height: 30px;
          background: #fcfcfc;
          // border-radius: 4px 4px 0px 0px;
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          padding-left: 20px;
          border: none;
          text-align: left;
          // border-top: 1px solid #ededed;
          // border-right: 1px solid #ededed;
        }
        td {
          border-top: 1px solid #ededed;
          font-size: 13px;
          font-weight: 400;
          color: #333333;
          border-right: 1px solid #ededed;
          height: 102px;
          // border-bottom: 1px solid #ededed;
          // text-align: center;
        }
        .no_evalution_users {
          text-align: left;
          .users_info {
            display: flex;
            padding-left: 20px;
            flex-wrap: wrap;
            .users_detail {
              margin-left: 5px;
              p {
                margin-top: 11px;
              }
            }
          }
          .yuyue_times {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            margin-top: 14px;
            margin-left: 20px;
            span {
              color: #666666;
            }
          }
        }
        .no_evalution_caozuo {
          border-right: none;
        }
        /deep/ .el-tag {
          height: 26px;
          border: 1px solid rgba(24, 144, 255, 0.5);
          // text-align: center;
          display: block;
          margin: auto;
          margin-top: 10px;
        }
        .settlement {
          color: #666666;
        }
        .overs {
          color: #666666;
        }
      }
      // 评价信息
      .evaluation_table {
        margin-top: 20px;
        width: 1160px;
        height: 133px;
        border-radius: 4px;
        // border: 1px solid #ededed;
        tr {
          text-align: center;
        }
        .evaluation_info {
          margin: 0 auto;
          width: 404px;
          font-size: 13px;
          font-weight: 400;
          color: #333333;
          text-align: left;
          line-height: 24px;
        }
        .no_number {
          height: 30px;
          background: #fcfcfc;
          // border-radius: 4px 4px 0px 0px;
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          border: none;
          border-top: 1px solid #ededed;
          border-right: 1px solid #ededed;
        }
        td {
          border-top: 1px solid #ededed;
          font-size: 13px;
          font-weight: 400;
          color: #333333;
          border-right: 1px solid #ededed;
          border-bottom: 1px solid #ededed;
          height: 102px;
          // text-align: center;
        }
        .block {
          font-size: 13px;
          font-weight: 400;
          color: #e6a23b;
          line-height: 20px;
        }
        .video {
          width: 68px;
          height: 28px;
          border-radius: 4px;
          border: 1px solid rgba(24, 144, 255, 0.5);
          background: #ffffff;
          font-size: 13px;
          font-weight: 400;
          color: #1890ff;
          outline: none;
          .iconfont {
            margin-right: 5px;
          }
        }
        .no_evalution_users {
          text-align: left;

          .users_info {
            display: flex;
            padding-left: 20px;
            flex-wrap: wrap;
            .users_detail {
              margin-left: 5px;
              p {
                margin-top: 11px;
              }
            }
            .yuyue_times {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              margin-top: 14px;
              span {
                color: #666666;
              }
            }
          }
        }
        .no_evalution_caozuo {
          border-right: none;
        }
        /deep/ .el-tag {
          height: 26px;
          border: 1px solid rgba(24, 144, 255, 0.5);
          // text-align: center;
          display: block;
          margin: auto;
          margin-top: 10px;
        }
        .settlement {
          color: #666666;
        }
        .overs {
          color: #666666;
        }
      }
    }
  }
}
</style>
